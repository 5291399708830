import React from "react";
import TopNavbar from "./component/navbar/TopNavbar";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";

function ChatManagement() {
  return (
    <div>
      <div className="container-scroller">
        {/* Topbar */}
        <TopNavbar />

        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="page-header">
                <h3 class="page-title">
                  <span class="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i class="mdi mdi-home"></i>
                  </span>
                  All Chats
                </h3>
                <nav aria-label="breadcrumb">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="">Chat Management</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <span></span>All Chats
                    </li>
                  </ul>
                </nav>
              </div>

              <div id="frame">
                <div id="sidepanel">
                  <div id="profile">
                    <div class="wrap">
                      <img
                        id="profile-img"
                        src="http://emilcarlsson.se/assets/mikeross.png"
                        class="online"
                        alt=""
                      />
                      <p>Mike Ross</p>
                      <i
                        class="mdi mdi-arrow-down-drop-circle expand-button"
                        aria-hidden="true"
                      ></i>
                      <div id="status-options">
                        <ul>
                          <li id="status-online" class="active">
                            <span class="status-circle"></span> <p>Online</p>
                          </li>
                          <li id="status-away">
                            <span class="status-circle"></span> <p>Away</p>
                          </li>
                          <li id="status-busy">
                            <span class="status-circle"></span> <p>Busy</p>
                          </li>
                          <li id="status-offline">
                            <span class="status-circle"></span> <p>Offline</p>
                          </li>
                        </ul>
                      </div>
                      <div id="expanded">
                        <label for="facebook">
                          <i class="mdi mdi-facebook" aria-hidden="true"></i>
                        </label>
                        <input name="facebook" type="text" value="mikeross" />
                        <label for="twitter">
                          <i class="mdi mdi-twitter" aria-hidden="true"></i>
                        </label>
                        <input name="twitter" type="text" value="ross81" />
                        <label for="linkedin">
                          <i class="mdi mdi-linkedin" aria-hidden="true"></i>
                        </label>
                        <input name="linkedin" type="text" value="mike.ross" />
                      </div>
                    </div>
                  </div>

                  <div id="search">
                    <label for="">
                      <i class="mdi mdi-account-search" aria-hidden="true"></i>
                    </label>
                    <input type="text" placeholder="Search contacts..." />
                  </div>

                  <div id="contacts">
                    <ul>
                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status online"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status offlinne"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status busy"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status online"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status offlinne"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status offlinne"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>

                      <li class="contact">
                        <div class="wrap">
                          <span class="contact-status"></span>
                          <img
                            src="http://emilcarlsson.se/assets/jonathansidwell.png"
                            alt=""
                          />
                          <div class="meta">
                            <p class="name">Jonathan Sidwell</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div id="bottom-bar">
                    <button id="addcontact">
                      <i class="mdi mdi-account-plus"></i>{" "}
                      <span>Add contact</span>
                    </button>
                    <button id="settings">
                      <i class="mdi mdi-wrench"></i> <span>Settings</span>
                    </button>
                  </div>
                </div>

                <div class="content">
                  <div class="contact-profile">
                    <img
                      src="http://emilcarlsson.se/assets/harveyspecter.png"
                      alt=""
                    />
                    <span>Harvey Specter</span>
                    <div class="social-media">
                      <i class="mdi mdi-facebook" aria-hidden="true"></i>
                      <i class="mdi mdi-twitter" aria-hidden="true"></i>
                      <i class="mdi mdi-linkedin" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="messages">
                    <ul>
                      <li class="sent">
                        <img
                          src="http://emilcarlsson.se/assets/mikeross.png"
                          alt=""
                        />
                        <p>
                          How the hell am I supposed to get a jury to believe
                          you when I am not even sure that I do?!
                        </p>
                      </li>
                      <li class="replies">
                        <img
                          src="http://emilcarlsson.se/assets/harveyspecter.png"
                          alt=""
                        />
                        <p>
                          When you're backed against the wall, break the god
                          damn thing down.
                        </p>
                      </li>
                      <li class="replies">
                        <img
                          src="http://emilcarlsson.se/assets/harveyspecter.png"
                          alt=""
                        />
                        <p>Excuses don't win championships.</p>
                      </li>
                      <li class="sent">
                        <img
                          src="http://emilcarlsson.se/assets/mikeross.png"
                          alt=""
                        />
                        <p>Oh yeah, did Michael Jordan tell you that?</p>
                      </li>
                      <li class="replies">
                        <img
                          src="http://emilcarlsson.se/assets/harveyspecter.png"
                          alt=""
                        />
                        <p>No, I told him that.</p>
                      </li>
                      <li class="replies">
                        <img
                          src="http://emilcarlsson.se/assets/harveyspecter.png"
                          alt=""
                        />
                        <p>
                          What are your choices when someone puts a gun to your
                          head?
                        </p>
                      </li>
                      <li class="sent">
                        <img
                          src="http://emilcarlsson.se/assets/mikeross.png"
                          alt=""
                        />
                        <p>
                          What are you talking about? You do what they say or
                          they shoot you.
                        </p>
                      </li>
                      <li class="replies">
                        <img
                          src="http://emilcarlsson.se/assets/harveyspecter.png"
                          alt=""
                        />
                        <p>
                          Wrong. You take the gun, or you pull out a bigger one.
                          Or, you call their bluff. Or, you do any one of a
                          hundred and forty six other things.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="message-input">
                    <div class="wrap">
                      <input type="text" placeholder="Write your message..." />
                      <i
                        class="mdi mdi-paperclip attachment"
                        aria-hidden="true"
                      ></i>
                      <button class="submit">
                        <i class="mdi mdi-send" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
          {/* Main Content */}
        </div>
      </div>
    </div>
  );
}

export default ChatManagement;

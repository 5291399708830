import { ActionTypes } from "../constants/action-types";
export const dashboardData = () => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/dashboard`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.DASHBOARD_DATA,
    payload: { ...data, loading: false },
  });
};
export const getProfile = () => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/profile`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.GET_PROFILE,
    payload: data,
  });
};

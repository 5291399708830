import React, { useState, useEffect } from "react";
import Sidebar from "./component/navbar/SideBar";
import { validateInput } from "./helpers/util";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../src/component/loader/Loader";
import {
  addNewUser,
  clearMessage,
  editUser,
} from "./redux/actions/userActions";
import { ActionTypes } from "./redux/constants/action-types";

function AddOrEditUserComponent() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEditMode = location.pathname?.includes("/edit-user");

  const userId = id;
  const { users, message, loading } = useSelector((state) => state.users);
  const user = users?.find((el) => el.id === parseInt(userId));
  const currentPage = new URLSearchParams(location.search).get("p") || 1;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: false } });
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setEmail(user?.email || "");
    }
  }, [isEditMode, userId]);

  useEffect(() => {
    if (
      message?.toLowerCase() === "user updated successfully" ||
      message?.toLowerCase() === "user added successfully"
    ) {
      setMessageVisible(true);

      const delayNavigation = setTimeout(() => {
        if (isEditMode) {
          navigate(`/all-users?p=${currentPage}`);
        } else {
          navigate("/all-users?p=1");
        }
      }, 2000);

      return () => clearTimeout(delayNavigation);
    }
    setMessageVisible(true);
  }, [message]);

  const handleSubmit = () => {
    if (isEditMode) {
      const dataSent = {
        userId,
        firstName,
        lastName,
        email,
      };
      dispatch(editUser(dataSent));
    } else {
      if (password !== confirmPassword) {
        setErrors({
          ...errors,
          confirmPassword: "Password did not match",
        });
        setIsFormValid(false);
      } else if (isFormValid) {
        dispatch(addNewUser(firstName, lastName, email, password));
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (isEditMode) {
      if (
        errors.firstName === "" ||
        errors.lastName === "" ||
        errors.email === ""
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if (
        errors.firstName === "" &&
        errors.lastName === "" &&
        errors.email === "" &&
        errors.password === "" &&
        errors.confirmPassword === ""
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setErrors({
        ...errors,
        firstName: "",
      });
      setFirstName(value);
    } else if (name === "lastName") {
      setErrors({
        ...errors,
        lastName: "",
      });
      setLastName(value);
    } else if (name === "email") {
      setErrors({
        ...errors,
        email: "",
      });
      setEmail(value);
    } else if (name === "password") {
      setErrors({
        ...errors,
        password: "",
      });
      setPassword(value);
    } else {
      setErrors({
        ...errors,
        confirmPassword: "",
      });
      setConfirmPassword(value);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value, password);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };
  const buttonClasses = `btn btn-gradient-primary mr-2 ${
    !isFormValid ? "disabled" : ""
  }`;

  const getMessageStyle = () => {
    if (
      message?.toLowerCase() === "user updated successfully" ||
      message?.toLowerCase() === "user added successfully"
    ) {
      return "success-message";
    } else {
      return "error-message";
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Users
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/all-users?p=1">All Users</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>
                      {isEditMode ? "Edit User" : "Add New User"}
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        {isEditMode ? "Edit User" : "Add New User"}
                        {loading && <Loader />}

                        <span className="float-right">
                          <Link
                            to="/all-users?p=1"
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link>
                        </span>
                      </h4>
                      <p className={getMessageStyle()}>
                        {messageVisible && message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        {isEditMode ? "Edit User" : "Add New User"}
                      </h4>

                      <form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputName1">
                                First Name
                              </label>
                              <input
                                name="firstName"
                                value={firstName}
                                type="text"
                                className="form-control"
                                id="exampleInputName1"
                                placeholder="First Name"
                                data-dashlane-rid="1e0992f288aade7f"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-9"
                                data-form-type="name"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.firstName && (
                                <p style={{ color: "red" }}>
                                  {errors.firstName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputName2">
                                Last Name
                              </label>
                              <input
                                name="lastName"
                                value={lastName}
                                type="text"
                                className="form-control"
                                id="exampleInputName2"
                                placeholder="Last Name"
                                data-dashlane-rid="1e0992f288aade7f"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-9"
                                data-form-type="name"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.lastName && (
                                <p style={{ color: "red" }}>
                                  {errors.lastName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail3">
                                Email address
                              </label>
                              <input
                                name="email"
                                value={email}
                                type="email"
                                className="form-control"
                                id="exampleInputEmail3"
                                placeholder="Email"
                                data-dashlane-rid="6a3fdf0806285b0a"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-10"
                                data-form-type="email"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.email && (
                                <p style={{ color: "red" }}>{errors.email}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        {!isEditMode && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword4">
                                  Password
                                </label>
                                <div className="showpwrd">
                                  <input
                                    name="password"
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    id="exampleInputPassword4"
                                    placeholder="Password"
                                    data-dashlane-rid="89b55a5026fdd08d"
                                    data-kwimpalastatus="alive"
                                    data-kwimpalaid="1685956648162-11"
                                    data-form-type="password"
                                    onChange={handleChange}
                                    onBlur={handleInputBlur}
                                  />

                                  <i
                                    onClick={handleClickShowPassword}
                                    className={`mdi ${
                                      showPassword
                                        ? "mdi-eye-outline"
                                        : "mdi-eye-off-outline"
                                    } menu-icon`}
                                  ></i>
                                </div>
                                {errors.password && (
                                  <p style={{ color: "red" }}>
                                    {errors.password}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputConfirmPassword1">
                                  Confirm Password
                                </label>
                                <div className="showpwrd">
                                  <input
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    className="form-control"
                                    id="exampleInputConfirmPassword1"
                                    placeholder="Confirm Password"
                                    data-dashlane-rid="31050e9eefee0b5a"
                                    data-kwimpalastatus="alive"
                                    data-kwimpalaid="1685956648162-3"
                                    data-form-type="password,confirmation"
                                    onChange={handleChange}
                                    onBlur={handleInputBlur}
                                  />
                                  <i
                                    onClick={handleClickShowConfirmPassword}
                                    className={`mdi ${
                                      showConfirmPassword
                                        ? "mdi-eye-outline"
                                        : "mdi-eye-off-outline"
                                    } menu-icon`}
                                  ></i>
                                </div>
                                {errors.confirmPassword && (
                                  <p style={{ color: "red" }}>
                                    {errors.confirmPassword}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        <button
                          type="button"
                          className={buttonClasses}
                          disabled={!isFormValid ? true : false}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                        <Link
                          to="/all-users?p=1"
                          className="btn btn-light"
                          data-dashlane-rid="b3088ec9f9c2421f"
                          data-dashlane-label="true"
                          data-form-type="other"
                        >
                          Cancel
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}

          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    </div>
  );
}
export default AddOrEditUserComponent;

import { ActionTypes } from "../constants/action-types";

const initialState = {
  token: localStorage.getItem("accessToken") || "",
  message: "",
  success: false,
  code: "",
  meta: {},
  loading: false,
};

export const loginReducer = (state = initialState, action) => {
  let code;
  let message;
  let success;
  let data;
  let loading;
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
      ({ success, code, message, data, loading } = action.payload);
      if (code === 400) {
        return {
          ...state,
          code,
          message: "Invalid email or password",
          success,
          loading,
        };
      }
      return {
        ...state,
        success,
        code,
        message,
        token: data,
        loading,
      };
    case ActionTypes.LOADING_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.FORGOT_PASSWORD:
    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.LOGOUT_USER:
      return {
        ...state,
        token: "",
        message: "",
      };
    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        message: "",
        success: false,
        code: "",
        loading: false,
      };
    default:
      return state;
  }
};

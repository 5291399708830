import { ActionTypes } from "../constants/action-types";
export const getChat = (currentPage, value) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/chat?page=${currentPage}&pageSize=${value}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.GET_CHAT,
    payload: { ...data, loading: false },
  });
};

export const getChatDetails = (id, currentPage, value) => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/chat-detail/${id}?page=${currentPage}&pageSize=${value}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.GET_CHAT_DETAILS,
    payload: data,
  });
};

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearError, login } from "./redux/actions/loginActions";
import { emailregex } from "./helpers/util";
import { Loader } from "../src/component/loader/Loader";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  useEffect(() => {
    if (errors.email === "" && errors.password === "" && errors.form === "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [Object.keys(errors).length]);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const signinHandler = (event) => {
    event.preventDefault();
    dispatch(login(email, password));
  };

  useEffect(() => {
    setErrors({
      ...errors,
      form: "",
    });
    if (loginState?.success === true && loginState.token !== "") {
      localStorage.setItem("accessToken", loginState?.token);

      navigate("/dashboard");
    } else if (loginState?.success === false) {
      setErrors({
        ...errors,
        form: loginState?.message,
      });
    }
  }, [loginState]);

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setErrors({
        ...errors,
        email: "",
        form: "",
      });
      setEmail(value);
    } else {
      setErrors({
        ...errors,
        password: "",
        form: "",
      });
      setPassword(value);
    }
  };

  const ValidateInput = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      if (value === "") {
        setErrors({
          ...errors,
          [name]: "Email is required",
        });
      } else if (!emailregex.test(value)) {
        setErrors({
          ...errors,
          [name]: "Please enter a valid email address",
        });
      }
    }

    if (name === "password" && value === "") {
      setErrors({
        ...errors,
        [name]: "Password is required",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      signinHandler(e);
    }
  };

  const buttonClasses = `btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn ${
    !isFormValid ? "disabled" : ""
  }`;
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                   <img style={{height: '70px'}} src="./assets/vendors/images/deehiy-logo-new.jpg" />
                  </div>
                  {errors?.form && !loginState?.loading && (
                    <p style={{ color: "red" }}>{errors.form}</p>
                  )}
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form
                    className="pt-3"
                    onSubmit={signinHandler}
                    id="loginForm"
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={ValidateInput}
                        onKeyUp={handleKeyPress}
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="showPasswordLogin">
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control form-control-lg"
                          id="password"
                          name="password"
                          value={password}
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={ValidateInput}
                          onKeyUp={handleKeyPress}
                        />
                        <i
                          className={`mdi ${
                            showPassword
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                          } menu-icon`}
                          onClick={showPasswordHandler}
                        ></i>
                      </div>
                      {errors.password && (
                        <p style={{ color: "red" }}>{errors.password}</p>
                      )}
                    </div>

                    <div className="mt-3">
                      {loginState.loading ? (
                        <Loader />
                      ) : (
                        <button
                          type="submit"
                          className={buttonClasses}
                          disabled={!isFormValid ? true : false}
                        >
                          SIGN IN
                        </button>
                      )}
                    </div>

                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <Link
                        to="/forgot-password"
                        className="auth-link text-black"
                      >
                        {" "}
                        Forgot password?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./component/navbar/Layout";
import Login from "./Login";
import Dashboard from "./Dashboard";
import AllUsers from "./AllUsers";
import ReportedUsers from "./ReportedUsers";
import ViewUser from "./ViewUser";
import AddOrEditUserComponent from "./AddOrEditUserComponent";
import ChatManagement from "./ChatManagement";
import BroadcastMessages from "./BroadcastMessages";
import ViewAllMessages from "./ViewAllMessages";
import SendNewMessage from "./SendNewMessage";
import Notification from "./Notification";
import Contact from "./Contact";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import NotFound from "./NotFound";
import ReportReasonList from "./ReportReasonList";
import AddOrEditReportReason from "./AddOrEditReportReason";
import { Navigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import { useSelector } from "react-redux";

function App() {
  const loginState = useSelector((state) => state.login);
  const isAuthorized = loginState?.token ? true : false;

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            isAuthorized ? (
              <Layout>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/all-users" element={<AllUsers />} />
                  <Route path="/reported-users" element={<ReportedUsers />} />
                  <Route
                    path="/add-new-user"
                    element={<AddOrEditUserComponent />}
                  />
                  <Route path="/view-user/:id" element={<ViewUser />} />
                  <Route
                    path="/view-reported-user/:id"
                    element={<ViewUser />}
                  />
                  <Route
                    path="/edit-user/:id"
                    element={<AddOrEditUserComponent />}
                  />
                  <Route
                    path="/report-reason-list"
                    element={<ReportReasonList />}
                  />
                  <Route
                    path="/add-new-reason"
                    element={<AddOrEditReportReason />}
                  />
                  <Route
                    path="/edit-reason/:id"
                    element={<AddOrEditReportReason />}
                  />
                  <Route
                    path="/broadcast-messages"
                    element={<BroadcastMessages />}
                  />
                  <Route
                    path="/send-new-message"
                    element={<SendNewMessage />}
                  />
                  <Route
                    path="/view-all-messages/:id"
                    element={<ViewAllMessages />}
                  />
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/chat-management"
          element={
            isAuthorized ? <ChatManagement /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/notification"
          element={
            isAuthorized ? <Notification /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/contact"
          element={
            isAuthorized ? <Contact /> : <Navigate to="/login" replace />
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:userId/:token"
          element={<ResetPassword />}
        />
        <Route
          path="*"
          element={
            isAuthorized ? <NotFound /> : <Navigate to="/login" replace />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  clearMessage,
  deleteUser,
  changeUserStatus,
} from "./redux/actions/userActions";
import { Loader } from "../src/component/loader/Loader";
import { NoData } from "../src/component/noDataText/NoData";

function AllUsers() {
  const [value, setValue] = useState(20);
  const [startingSerialNumber, setStartingSerialNumber] = useState(0);
  const dispatch = useDispatch();
  const { users, totalPages, loading, deleteUserData, userStatusData } =
    useSelector((state) => state.users);

  const location = useLocation();
  const navigate = useNavigate();
  const page = new URLSearchParams(location.search).get("p") || 1;
  const [currentPage, setCurrentPage] = useState(Number(page));

  useEffect(() => {
    const newPage = Number(page);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [location.search]);

  const options = [
    { id: "1", label: "50", value: "50" },
    { id: "2", label: "20", value: "20" },
    { id: "3", label: "10", value: "10" },
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const previousHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(fetchUsers(currentPage, value));
    navigate(`${window.location.pathname}?p=${currentPage?.toString()}`);
    const newStartingNumber = (currentPage - 1) * value + 1;
    setStartingSerialNumber(newStartingNumber);
    return () => {
      dispatch(clearMessage());
    };
  }, [currentPage, value, deleteUserData, userStatusData]);

  const deleteHandler = (userId) => {
    dispatch(deleteUser(userId));
  };

  const unBlockHandler = (userId) => {
    dispatch(changeUserStatus(userId, 0));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Users
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>User Account Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>All Users
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        All Users
                        <span className="float-right">
                          <Link
                            to="/add-new-user"
                            className="btn btn-sm btn-primary"
                          >
                            Add New User
                          </Link>
                        </span>
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">S.No</th>
                              <th className="font-weight-bold">Name</th>
                              <th className="font-weight-bold">Email</th>
                              {/* <th className="font-weight-bold">Description</th> */}
                              <th className="font-weight-bold">Last Login</th>
                              <th className="font-weight-bold">Status</th>
                              <th className="font-weight-bold">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {users.length === 0 ? (
                              <NoData />
                            ) : (
                              users?.map((emp, index) => (
                                <tr key={emp.id}>
                                  <td> {startingSerialNumber + index}</td>
                                  <td
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      src="./../assets/vendors/images/faces/profile.png"
                                      className="mr-2"
                                      alt="userProfile"
                                    />
                                    <div
                                      style={{
                                        whiteSpace: "normal",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      {emp.firstName} {emp.lastName}
                                    </div>
                                  </td>
                                  <td>{emp.email}</td>
                                  {/* <td>Fund is not recieved</td> */}
                                  <td>{emp?.lastLogin?.split("T")[0]}</td>
                                  <td>
                                    {emp.isBlocked === 1 ? (
                                      <label className="badge badge-gradient-danger">
                                        BLOCKED
                                      </label>
                                    ) : (
                                      <label className="badge badge-gradient-success">
                                        ACTIVE
                                      </label>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/view-user/${emp.id}?p=${currentPage}`}
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="View"
                                    >
                                      <i className="mdi mdi-eye"></i>
                                    </Link>

                                    <Link
                                      to={`/edit-user/${emp.id}?p=${currentPage}`}
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Edit"
                                    >
                                      <i className="mdi mdi-pencil-box-outline"></i>
                                    </Link>

                                    <Link
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Remove"
                                      onClick={() => deleteHandler(emp.id)}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </Link>
                                    {emp.isBlocked === 1 && (
                                      <Link
                                        className="btn btn-sm"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Unblock"
                                        onClick={() => unBlockHandler(emp.id)}
                                      >
                                        <i className="mdi mdi-lock-open"></i>
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-3 d-flex align-items-center justify-content-center">
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === 1 && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={previousHandler}
                          disabled={currentPage === 1}
                        >
                          &lt; Previous
                        </button>
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === totalPages && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={nextHandler}
                          disabled={currentPage === totalPages}
                        >
                          Next &gt;
                        </button>

                        <div className="mt-1 ml-5 d-flex align-items-center justify-content-center">
                          <label>
                            Page Size
                            <select
                              value={value}
                              onChange={handleChange}
                              className={`btn dropdown btn-sm ml-2 ${
                                currentPage !== 1 && "disabled"
                              }`}
                              disabled={currentPage === 1 ? false : true}
                            >
                              {options.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.value}
                                  style={{ fontSize: "15px" }}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}
        </div>
      </div>
    </div>
  );
}

export default AllUsers;

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/actions/dashboardActions";
import { logout } from "../../redux/actions/loginActions";
import { ActionTypes } from "../../redux/constants/action-types";
function TopNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.dashboard);
  const firstName = profileData?.firstName || "";
  const lastName = profileData?.lastName || "";
  const image = profileData?.image || "";

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  const logoutHandler = () => {
    dispatch(logout());
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: false } });
    navigate("/login");
  };

  return (
    <div>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        {/* <!-- Starting Of Company Logo --> */}
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo">
           <img style={{height: '65px', marginTop: '20px'}} src="./../assets/vendors/images/deehiy-logo-new.jpg" alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini">
            <img src="./../assets/vendors/images/logo.webp" alt="logo" />
          </Link>
        </div>
        {/* <!-- Ending Of Company Logo --> */}

        {/* <!-- Stating of Top search Bar --> */}
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          {/* <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="mdi mdi-menu"></span>
          </button> */}
          <div className="search-field d-none d-md-block">
            {/* <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search projects"
                />
              </div>
            </form> */}
          </div>
          {/* <!-- Ending Of Top Search Bar --> */}

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  <img src={image} alt="profile" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    {firstName} {lastName}
                  </p>
                </div>
              </Link>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                {/* <a className="dropdown-item" href="#">
                  <i className="mdi mdi-cached mr-2 text-success"></i> Activity Log
                </a> */}

                <Link className="dropdown-item" to="/change-password">
                  <i className="mdi mdi-cached mr-2 text-success"></i> Change
                  Password
                </Link>

                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="/login"
                  onClick={() => localStorage.clear()}
                >
                  <i className="mdi mdi-logout mr-2 text-primary"></i> Sign Out
                </a>
              </div>
            </li>

            {/* <li className="nav-item d-none d-lg-block full-screen-link">
              <Link to="/notification">
                <button type="button" className="notification">
                  <span>
                    <i className="mdi mdi-bell" id="notification-button"></i>
                  </span>
                  <span className="notification-badge">2</span>
                </button>
              </Link>
            </li> */}
            {/* <div className="notification">
                <Link className="nav-link" to="/notification">
                  <i className="mdi mdi-bell" id="notification-button"></i>

                  <span>5</span>
                </Link>
              </div> */}

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="messageDropdown"
                href="#"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-email-outline"></i>
                <span className="count-symbol bg-warning"></span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="messageDropdown"
              >
                <h6 className="p-3 mb-0">Messages</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/vendors/images/faces/face4.jpg"
                      alt="image"
                      className="profile-pic"
                    />
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6
                      className="preview-subject ellipsis mb-1 font-weight-normal"
                    >
                      Mark send you a message
                    </h6>
                    <p className="text-gray mb-0">1 Minutes ago</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/vendors/images/faces/face2.jpg"
                      alt="image"
                      className="profile-pic"
                    />
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6
                      className="preview-subject ellipsis mb-1 font-weight-normal"
                    >
                      Cregh send you a message
                    </h6>
                    <p className="text-gray mb-0">15 Minutes ago</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/vendors/images/faces/face3.jpg"
                      alt="image"
                      className="profile-pic"
                    />
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6
                      className="preview-subject ellipsis mb-1 font-weight-normal"
                    >
                      Profile picture updated
                    </h6>
                    <p className="text-gray mb-0">18 Minutes ago</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">4 new messages</h6>
              </div>
            </li> */}

            {/*  <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <h6 className="p-3 mb-0">Notifications</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Event today
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      Just a reminder that you have an event today
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Settings
                    </h6>
                    <p className="text-gray ellipsis mb-0">Update dashboard</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div
                    className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  >
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Launch Admin
                    </h6>
                    <p className="text-gray ellipsis mb-0">New admin wow!</p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">See all notifications</h6>
              </div>
            </li> */}

            <li
              className="nav-item nav-logout d-none d-lg-block"
              onClick={logoutHandler}
            >
              <Link className="nav-link">
                <i className="mdi mdi-power"></i>
              </Link>
            </li>

            {/* <!-- <li className="nav-item nav-settings d-none d-lg-block"> */}
            {/* <a className="nav-link" href="#">
                <i className="mdi mdi-format-line-spacing"></i>
              </a>
            </li> --> */}
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default TopNavbar;

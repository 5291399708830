import React, { useEffect, useState } from "react";
import socket from "./helpers/socket";
import Sidebar from "./component/navbar/SideBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getChat } from "./redux/actions/chatActions";
import { Loader } from "./component/loader/Loader";
import { clearMessage } from "./redux/actions/userActions";
import { NoData } from "../src/component/noDataText/NoData";

function BroadcastMessages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading } = useSelector((state) => state.chat);
  const { profileData } = useSelector((state) => state.dashboard);
  const chatList = data?.chatList || [];
  const totalPages = data?.totalPages || 1;
  const page = new URLSearchParams(location.search).get("p");
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [value, setValue] = useState(20);
  const [startingSerialNumber, setStartingSerialNumber] = useState(0);
  const options = [
    { id: "1", label: "50", value: "50" },
    { id: "2", label: "20", value: "20" },
    { id: "3", label: "10", value: "10" },
  ];

  useEffect(() => {
    const newPage = Number(page);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [location.search]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const previousHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextHandler = () => {
    setCurrentPage(currentPage + 1);
  };
  const user = [];
  for (const chat of chatList) {
    const groupMembers = chat.groupMember;
    const notAdmin = groupMembers.filter((member) => member.isAdmin === 0);
    user.push(...notAdmin);
  }

  const userId = profileData?.id || "";
  const groupProps = {
    userId,
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getChat(currentPage, value));
    navigate(`${window.location.pathname}?p=${currentPage?.toString()}`);
    const newStartingNumber = (currentPage - 1) * value + 1;
    setStartingSerialNumber(newStartingNumber);
    return () => {
      dispatch(clearMessage());
    };
  }, [currentPage, value]);

  const userConnectHandler = () => {
    socket.emit("connect_user", groupProps, (ackData) => {
      console.log("Server acknowledged:", ackData);
    });
    navigate("/send-new-message");
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Chats
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Chat Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>All Chats
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        Broadcast Messages
                        <span className="float-right">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={userConnectHandler}
                          >
                            Send New Message
                          </button>
                        </span>
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">S.No</th>
                              <th className="font-weight-bold">User</th>
                              <th className="font-weight-bold">
                                Last Sent Message
                              </th>
                              <th className="font-weight-bold">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {chatList?.length === 0 ? (
                              <NoData />
                            ) : (
                              chatList?.map((emp, index) => (
                                <tr key={emp.id}>
                                  <td style={{ width: "10%" }}>
                                    {startingSerialNumber + index}
                                  </td>
                                  <td
                                    style={{
                                      width: "25%",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {
                                      user?.find(
                                        (member) => member.groupId === emp.id
                                      )?.user?.firstName
                                    }{" "}
                                    {
                                      user?.find(
                                        (member) => member.groupId === emp.id
                                      )?.user?.lastName
                                    }
                                  </td>
                                  {emp.messages?.map((msg) => (
                                    <td key={msg.id} style={{ width: "55%" }}>
                                      {msg.message?.length > 70
                                        ? `${msg.message.substring(0, 70)}...`
                                        : `${msg.message}`}
                                    </td>
                                  ))}
                                  <td style={{ width: "10%" }}>
                                    <Link
                                      to={`/view-all-messages/${emp.id}?p=${currentPage}`}
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="View"
                                    >
                                      <i className="mdi mdi-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-3 d-flex align-items-center justify-content-center">
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === 1 && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={previousHandler}
                          disabled={currentPage === 1}
                        >
                          &lt; Previous
                        </button>
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === totalPages && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={nextHandler}
                          disabled={currentPage === totalPages}
                        >
                          Next &gt;
                        </button>

                        <div className="mt-1 ml-5 d-flex align-items-center justify-content-center">
                          <label>
                            Page Size
                            <select
                              value={value}
                              onChange={handleChange}
                              className={`btn dropdown btn-sm ml-2 ${
                                currentPage !== 1 && "disabled"
                              }`}
                              disabled={currentPage === 1 ? false : true}
                            >
                              {options.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.value}
                                  style={{ fontSize: "15px" }}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
          {/* Main Content */}
        </div>
      </div>
    </div>
  );
}

export default BroadcastMessages;

import React from "react";
import { TailSpin } from "react-loader-spinner";
export const Loader = () => {
  return (
    <div className="spinner">
      <TailSpin
        height="50"
        width="80"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible={true}
      />
    </div>
  );
};

export default Loader;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "./redux/actions/loginActions";
import { clearError } from "./redux/actions/loginActions";
import { validateInput } from "./helpers/util";
import { Loader } from "../src/component/loader/Loader";
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const dispatch = useDispatch();
  const {
    loading,
    message: loginMessage,
    success,
  } = useSelector((state) => state.login);
  useEffect(() => {
    if (errors.email === "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      dispatch(clearError());
      setErrors({
        ...errors,
        email: "",
      });
      setEmail(value);
    }
  };
  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };

  const submitHandler = () => {
    if (isFormValid) {
      dispatch(forgotPassword(email));
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      submitHandler(e);
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);
  useEffect(() => {
    setMessage(loginMessage);
  }, [loginMessage]);
  const buttonClasses = `btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn ${
    !isFormValid ? "disabled" : ""
  }`;
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                   <img style={{height: '70px'}} src="./assets/vendors/images/deehiy-logo-new.jpg" />
                  </div>
                  {loginMessage !== "" && (
                    <p
                      style={
                        success
                          ? { color: "green", fontSize: "18px" }
                          : { color: "red" }
                      }
                    >
                      {loginMessage}
                    </p>
                  )}
                  {!success && (
                    <>
                      <h4>Forgot Password</h4>

                      <form className="pt-3">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                            onKeyUp={handleKeyPress}
                          />
                          {errors.email && (
                            <p style={{ color: "red" }}>{errors.email}</p>
                          )}
                        </div>

                        <div className="mt-3">
                          {loading ? (
                            <Loader />
                          ) : (
                            <Link
                              onClick={submitHandler}
                              disabled={!isFormValid ? true : false}
                              className={buttonClasses}
                            >
                              SUBMIT
                            </Link>
                          )}
                        </div>
                      </form>
                    </>
                  )}
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Link to="/login" className="auth-link text-black">
                      {" "}
                      Back to Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

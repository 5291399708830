export const emailregex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const passwordregex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const validateInput = (name, value, password = "") => {
  if (name === "firstName") {
    if (value.trim().length === 0) {
      return "First Name is required";
    }
  }
  if (name === "lastName") {
    if (value.trim().length === 0) {
      return "Last Name is required";
    }
  }
  if (name === "chatMessage") {
    if (value.trim().length === 0) {
      return "Message is required";
    }
  }
  if (name === "email") {
    if (value === "") {
      return "Email is required";
    } else if (!emailregex.test(value)) {
      return "Please enter a valid email address";
    }
  }
  if (name === "oldPassword") {
    if (value.trim().length === 0) {
      return "Old Password is required";
    }
  }

  if (name === "password") {
    if (value === "") {
      return "Password is required";
    } else if (!passwordregex.test(value)) {
      return "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character";
    }
  }

  if (name === "confirmPassword") {
    if (value === "") {
      return "Confirm Password is required";
    } else if (value !== password) {
      return "Password did not match";
    }
  }
  if (name === "reason") {
    if (value.trim().length === 0) {
      return "Reason is required";
    }
  }
  if (name === "description") {
    if (value.trim().length === 0) {
      return "Description is required";
    }
  }
  return null;
};

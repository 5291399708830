import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/loginActions";
import { ActionTypes } from "../../redux/constants/action-types";
function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: false } });
    navigate("/login");
  };
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="menu-title">User Management</span>
              <i className="mdi mdi-contacts menu-icon"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/all-users?p=1">
                    All Users
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/add-new-user">
                    Add User
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/reported-users">
                    Reported Users
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/report-reason-list?p=1">
              <span className="menu-title">Report Reasons</span>
              <i className="mdi mdi mdi-alert-outline menu-icon"></i>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-chat"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="menu-title">Chat Management</span>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </a>
            <div className="collapse" id="ui-chat">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/broadcast-messages?p=1">
                    Broadcast messages
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          {/* <li className="nav-item">
            <Link className="nav-link" to="/chat-management">
              <span className="menu-title">Chat Management</span>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/notification">
              <span className="menu-title">Notifications</span>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </Link>
          </li> */}

          {/* <li className="nav-item">
            <Link className="nav-link" to="/contact">
              <span className="menu-title">Contact Us</span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li> */}

          <li className="nav-item" onClick={logoutHandler}>
            <Link className="nav-link">
              <span className="menu-title">Logout</span>
              <i className="mdi mdi-power menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "./redux/actions/loginActions";
import { validateInput } from "./helpers/util";
import { Loader } from "../src/component/loader/Loader";
import { clearError } from "./redux/actions/loginActions";
function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    message: loginMessage,
    success,
  } = useSelector((state) => state.login);
  useEffect(() => {
    if (errors.password === "" && errors.confirmPassword === "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [errors]);
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setErrors({
        ...errors,
        password: "",
      });
      setPassword(value);
    } else {
      setErrors({
        ...errors,
        confirmPassword: "",
      });
      setConfirmPassword(value);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value, password);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const submitHandler = () => {
    if (isFormValid) {
      const dataToSend = {
        password,
        confirmPassword,
        pathname: location.pathname?.split("/"),
      };
      dispatch(resetPassword(dataToSend));
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      submitHandler(e);
    }
  };
  const buttonClasses = `btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn ${
    !isFormValid ? "disabled" : ""
  }`;
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                   <img style={{height: '70px'}} src="../../assets/vendors/images/faces/deehiy-logo-new.jpg" />
                  </div>
                  {!success ? (
                    <>
                      <h4>Reset Password</h4>

                      <form className="pt-3">
                        <div className="form-group">
                          <div className="showresetpassword">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control form-control-lg"
                              id="password"
                              name="password"
                              value={password}
                              placeholder="New Password"
                              onChange={handleChange}
                              onBlur={handleInputBlur}
                              onKeyUp={handleKeyPress}
                            />
                            <i
                              onClick={showPasswordHandler}
                              className={`mdi ${
                                showPassword
                                  ? "mdi-eye-outline"
                                  : "mdi-eye-off-outline"
                              } menu-icon`}
                            ></i>
                          </div>
                          {errors.password && (
                            <p style={{ color: "red" }}>{errors.password}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <div className="showresetpassword">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control form-control-lg"
                              id="confirmPassword"
                              name="confirmPassword"
                              value={confirmPassword}
                              placeholder="Confirm New Password"
                              onChange={handleChange}
                              onBlur={handleInputBlur}
                              onKeyUp={handleKeyPress}
                            />
                            <i
                              onClick={showConfirmPasswordHandler}
                              className={`mdi ${
                                showConfirmPassword
                                  ? "mdi-eye-outline"
                                  : "mdi-eye-off-outline"
                              } menu-icon`}
                            ></i>
                          </div>
                          {errors.confirmPassword && (
                            <p style={{ color: "red" }}>
                              {errors.confirmPassword}
                            </p>
                          )}
                        </div>
                        <div className="mt-3">
                          {loading ? (
                            <Loader />
                          ) : (
                            <Link
                              onClick={submitHandler}
                              disabled={!isFormValid ? true : false}
                              className={buttonClasses}
                            >
                              SUBMIT
                            </Link>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <p style={{ color: "green", fontSize: "18px" }}>
                        {loginMessage}
                      </p>
                      <div className="mt-3">
                        <Link className={buttonClasses} to="/login">
                          LOGIN
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

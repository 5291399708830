import { ActionTypes } from "../constants/action-types";

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/login`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  const data = await response.json();

  dispatch({
    type: ActionTypes.LOGIN_USER,
    payload: { ...data, loading: false },
  });
};

export const logout = () => {
  localStorage.removeItem("accessToken");

  return {
    type: ActionTypes.LOGOUT_USER,
  };
};

export const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/forgot_password/${email}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.FORGOT_PASSWORD,
    payload: { ...data, loading: false },
  });
};
export const resetPassword = (datareceived) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/reset_password/${datareceived.pathname[2]}/${datareceived.pathname[3]}`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: datareceived.password,
      confirmPassword: datareceived.confirmPassword,
    }),
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.RESET_PASSWORD,
    payload: { ...data, loading: false },
  });
};
export const clearError = () => {
  return {
    type: ActionTypes.CLEAR_ERROR,
  };
};

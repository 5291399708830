import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  reportedUsers,
  clearMessage,
  changeReportStatus,
} from "./redux/actions/userActions";
import { Loader } from "../src/component/loader/Loader";
import NoData from "./component/noDataText/NoData";

function ReportedUsers() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(20);
  const [startingSerialNumber, setStartingSerialNumber] = useState(0);
  const { loading, reportedUserData, reportStatusData } = useSelector(
    (state) => state.users
  );

  const { data } = reportedUserData;
  const userList = data?.userList || [];
  const currentPage = data?.currentPage || 1;
  const totalPages = data?.totalPages || 1;

  const options = [
    { id: "1", label: "50", value: "50" },
    { id: "2", label: "20", value: "20" },
    { id: "3", label: "10", value: "10" },
  ];
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    dispatch(changeReportStatus(userId, 0));
  };

  const handleBlockUser = (userId) => {
    dispatch(changeReportStatus(userId, 1));
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(reportedUsers(currentPage, value));
    const newStartingNumber = (currentPage - 1) * value + 1;
    setStartingSerialNumber(newStartingNumber);
    return () => {
      dispatch(clearMessage());
    };
  }, [reportStatusData]);

  useEffect(() => {
    dispatch(reportedUsers(currentPage, value));
  }, [value, currentPage]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Users
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>User Account Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>Reported Users
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        Reported Users
                        {/* <span className="float-right">
                          <Link
                            to="/add-new-user"
                            className="btn btn-sm btn-primary"
                          >
                            Add New User
                          </Link>
                        </span> */}
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">S.No</th>
                              <th className="font-weight-bold">Blocked To</th>
                              <th className="font-weight-bold">Email</th>
                              {/* <th className="font-weight-bold">Description</th> */}
                              <th className="font-weight-bold">Blocked By</th>
                              <th className="font-weight-bold">Reason</th>
                              <th className="font-weight-bold">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {userList.length === 0 ? (
                              <NoData />
                            ) : (
                              userList.map((emp, index) => (
                                <tr key={emp.id}>
                                  <td>{startingSerialNumber + index}</td>
                                  <td>
                                    <img
                                      src="./../assets/vendors/images/faces/profile.png"
                                      className="mr-2"
                                      alt="image"
                                    />
                                    {emp.blockedTo?.firstName}{" "}
                                    {emp.blockedTo?.lastName}
                                  </td>
                                  <td>{emp.blockedTo?.email}</td>
                                  {/* <td>Fund is not recieved</td> */}
                                  <td>
                                    {emp.blockedBy?.firstName}{" "}
                                    {emp.blockedBy?.lastName}
                                  </td>
                                  <td>{emp.reason?.reason}</td>
                                  <td>
                                    <Link
                                      to={`/view-reported-user/${emp.id}`}
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="View"
                                    >
                                      <i className="mdi mdi-eye"></i>
                                    </Link>

                                    {/* <Link
                                    to="/edit-user"
                                    className="btn btn-sm"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Edit"
                                  >
                                    <i className="mdi mdi-pencil-box-outline"></i>
                                  </Link> */}

                                    <Link
                                      // to="#"
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Decline"
                                      onClick={() => handleDeleteUser(emp.id)}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </Link>

                                    <Link
                                      // to="#"
                                      className="btn btn-sm"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Block"
                                      onClick={() => handleBlockUser(emp.id)}
                                    >
                                      <i className="mdi mdi-block-helper"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-3 d-flex align-items-center justify-content-center">
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === 1 && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={() => {
                            if (currentPage > 1) {
                              dispatch(reportedUsers(currentPage - 1));
                            }
                          }}
                          disabled={currentPage === 1}
                        >
                          &lt; Previous
                        </button>
                        <button
                          className={`btn btn-sm btn-primary m-3 ${
                            currentPage === totalPages && "disabled"
                          }`}
                          style={{ width: 100 }}
                          onClick={() => {
                            if (currentPage < totalPages) {
                              dispatch(reportedUsers(currentPage + 1));
                            }
                          }}
                          disabled={currentPage === totalPages}
                        >
                          Next &gt;
                        </button>

                        <div className="mt-1 ml-5 d-flex align-items-center justify-content-center">
                          <label>
                            Page Size
                            <select
                              value={value}
                              onChange={handleChange}
                              className={`btn dropdown btn-sm ml-2 ${
                                currentPage !== 1 && "disabled"
                              }`}
                              disabled={currentPage === 1 ? false : true}
                            >
                              {options.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.value}
                                  style={{ fontSize: "15px" }}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}
        </div>
      </div>
    </div>
  );
}

export default ReportedUsers;

import { ActionTypes } from "../constants/action-types";

export const fetchUsers = (currentPage, value) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/get-users-list?page=${currentPage}&pageSize=${value}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });

  if (!response.ok) {
    throw new Error("Something went wrong!!");
  }
  const responseData = await response.json();

  dispatch({
    type: ActionTypes.FETCH_USER,
    payload: { ...responseData, loading: false },
  });
};

export const addNewUser =
  (firstName, lastName, email, password) => async (dispatch) => {
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
    const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/add-user`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
      }),
    });

    const responseData = await response.json();

    dispatch({
      type: ActionTypes.ADD_NEW_USER,
      payload: { ...responseData, loading: false },
    });
  };

export const reportedUsers = (currentPage, value) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/reported-user-list?page=${currentPage}&pageSize=${value}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });

  const responseData = await response.json();

  dispatch({
    type: ActionTypes.REPORTED_USERS,
    payload: { ...responseData, loading: false },
  });
};

export const reportReasonList = (currentPage, value) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/reason/get-reason-list?page=${currentPage}&pageSize=${value}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });

  const responseData = await response.json();

  dispatch({
    type: ActionTypes.REPORT_REASON_LIST,
    payload: { ...responseData, loading: false },
  });
};

export const addNewReason =
  (reason, description) => async (dispatch) => {
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
    const url = `${process.env.REACT_APP_ADMIN_API_URL}api/reason/add-report-reason`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        reason,
        description,
      }),
    });

    const responseData = await response.json();

    dispatch({
      type: ActionTypes.ADD_NEW_REASON,
      payload: { ...responseData, loading: false },
    });
  };

export const deleteReportReason = (userId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/reason/delete-report-reason/${userId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const responseData = await response.json();
  
  dispatch({
    type: ActionTypes.DELETE_REPORT_REASON,
    payload: responseData,
  });
};

export const updateReportReason = (dataReceived) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/reason/update-report-reason/${dataReceived.userId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      reason: dataReceived.reason,
      description: dataReceived.description,
    }),
  });
  const responseData = await response.json();
  
  dispatch({
    type: ActionTypes.UPDATE_REPORT_REASON,
    payload: { ...responseData, loading: false },
  });
};

export const changeReportStatus = (userId, isBlocked) => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/change-request-status/${userId}/${isBlocked}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });

  const responseData = await response.json();

  dispatch({
    type: ActionTypes.CHANGE_REPORT_STATUS,
    payload: responseData,
  });
};

export const changeUserStatus = (userId, isBlocked) => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/change-user-status/${userId}/${isBlocked}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });

  const responseData = await response.json();

  dispatch({
    type: ActionTypes.CHANGE_USER_STATUS,
    payload: responseData,
  });
};

export const editUser = (dataReceived) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/update-user/${dataReceived.userId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      firstName: dataReceived.firstName,
      lastName: dataReceived.lastName,
      email: dataReceived.email,
    }),
  });

  const responseData = await response.json();

  dispatch({
    type: ActionTypes.EDIT_USER,
    payload: { ...responseData, loading: false },
  });
};

export const deleteUser = (userId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/delete-user/${userId}`;
  const response = await fetch(url, {
    headers: {
      "x-auth-token": localStorage.getItem("accessToken"),
    },
  });
  const responseData = await response.json();

  dispatch({
    type: ActionTypes.DELETE_USER,
    payload: responseData,
  });
};

export const changePassword = (oldPassword, password) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: true } });
  const url = `${process.env.REACT_APP_ADMIN_API_URL}api/admin/change-password`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      oldPassword,
      password,
    }),
  });
  const data = await response.json();
  dispatch({
    type: ActionTypes.CHANGE_PASSWORD,
    payload: { ...data, loading: false },
  });
};

export const clearMessage = () => {
  return {
    type: ActionTypes.CLEAR_MESSAGE,
  };
};

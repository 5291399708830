import React, { useState, useEffect } from "react";
import Sidebar from "./component/navbar/SideBar";
import { validateInput } from "./helpers/util";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "./component/loader/Loader";
import { addNewReason, updateReportReason } from "./redux/actions/userActions";

function AddOrEditUserComponent() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEditMode = location.pathname?.includes("/edit-reason");

  const userId = id;
  const { reasonList, loading, reportReasonData, updatedReportedReasonData } =
    useSelector((state) => state.users);
  const report = reasonList?.find((el) => el.id === parseInt(userId));
  const currentPage = new URLSearchParams(location.search).get("p") || 1;

  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      setReason(report?.reason || "");
      setDescription(report?.description || "");
    }
  }, [isEditMode, userId]);

  const handleSubmit = () => {
    if (isEditMode) {
      const dataSent = {
        userId,
        reason,
        description,
      };
      dispatch(updateReportReason(dataSent));
    } else {
      dispatch(addNewReason(reason, description));
    }
    setMessageVisible(true);

    const delayNavigation = setTimeout(() => {
      if (isEditMode) {
        navigate(`/report-reason-list?p=${currentPage}`);
      } else {
        navigate("/report-reason-list?p=1");
      }
    }, 2000);

    return () => clearTimeout(delayNavigation);
  };

  useEffect(() => {
    if (isEditMode) {
      if (errors.reason === "" || errors.description === "") {
        setIsFormValid(true);
      }
    } else {
      if (errors.reason === "" && errors.description === "") {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "reason") {
      setErrors({
        ...errors,
        reason: "",
      });
      setReason(value);
    } else if (name === "description") {
      setErrors({
        ...errors,
        description: "",
      });
      setDescription(value);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };

  const buttonClasses = `btn btn-gradient-primary mr-2 ${
    !isFormValid ? "disabled" : ""
  }`;

  const getMessageStyle = () => {
    if (
      reportReasonData?.message?.toLowerCase() ===
        "report reason added successfully" ||
      updatedReportedReasonData?.message?.toLowerCase() ===
        "report reason updated successfully"
    ) {
      return "success-message";
    } else {
      return "error-message";
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  Report Reasons
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/report-reason-list?p=1">Report Reasons</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>
                      {isEditMode ? "Edit Report Reason" : "Add New Reason"}
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        {isEditMode ? "Edit Report Reason" : "Add New Reason"}
                        {loading && <Loader />}

                        <span className="float-right">
                          <Link
                            to="/report-reason-list?p=1"
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link>
                        </span>
                      </h4>
                      <p className={getMessageStyle()}>
                        {messageVisible &&
                          (isEditMode
                            ? updatedReportedReasonData.message
                            : reportReasonData.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        {isEditMode ? "Edit Report Reason" : "Add New Reason"}
                      </h4>

                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail3">Reason</label>
                              <input
                                name="reason"
                                value={reason}
                                type="text"
                                className="form-control"
                                id="exampleInputEmail3"
                                placeholder="Reason"
                                data-dashlane-rid="6a3fdf0806285b0a"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-10"
                                data-form-type="email"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.reason && (
                                <p style={{ color: "red" }}>{errors.reason}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail3">
                                Description
                              </label>
                              <textarea
                                name="description"
                                value={description}
                                type="text"
                                className="form-control"
                                id="exampleInputEmail3"
                                placeholder="Description"
                                data-dashlane-rid="6a3fdf0806285b0a"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-10"
                                data-form-type="email"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.description && (
                                <p style={{ color: "red" }}>
                                  {errors.description}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className={buttonClasses}
                          disabled={!isFormValid ? true : false}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                        <Link
                          to="/report-reason-list?p=1"
                          className="btn btn-light"
                          data-dashlane-rid="b3088ec9f9c2421f"
                          data-dashlane-label="true"
                          data-form-type="other"
                        >
                          Cancel
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}

          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    </div>
  );
}
export default AddOrEditUserComponent;

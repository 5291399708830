import React, { useState, useEffect } from "react";
import socket from "./helpers/socket";
import Sidebar from "./component/navbar/SideBar";
import { validateInput } from "./helpers/util";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "./redux/actions/userActions";
import { ThreeDots } from "react-loader-spinner";

function SendNewMessage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profileData } = useSelector((state) => state.dashboard);
  const senderId = profileData?.id || "";

  const [chatMessage, setChatMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    if (errors.chatMessage === "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "chatMessage") {
      setErrors({
        ...errors,
        chatMessage: "",
      });
      setChatMessage(value);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };
  const msgprops = {
    senderId,
    message: chatMessage,
  };
  const idprops = {
    userId: senderId,
  };
  const sendMessage = () => {
    if (isFormValid) {
      socket.emit("send_message_all", msgprops);
      socket.on("message_sent", (data) => {
        console.log("Message sent acknowledgment:", data);
        setLoading(false);
        setMessage(data?.data?.message);
        setMessageVisible(true);
      });
      setChatMessage("");
    } else {
      return;
    }
  };

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.emit("user_online_status", idprops, (ackData) => {
      console.log("Server acknowledged status:", ackData);
    });
  }, []);

  useEffect(() => {
    if (messageVisible) {
      const delayNavigation = setTimeout(() => {
        navigate("/broadcast-messages?p=1");
      }, 2000);
      return () => clearTimeout(delayNavigation);
    }
  }, [messageVisible]);

  const handleDisconnect = () => {
    socket.emit("disconnect_user", idprops, (ackData) => {
      console.log("Server acknowledged disconnecting:", ackData);
      socket.disconnect();
      setMessageVisible(false);
      navigate("/broadcast-messages?p=1");
    });
  };
  const buttonClasses = `btn btn-gradient-primary mr-2 ${
    !isFormValid ? "disabled" : ""
  }`;

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Chats
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/broadcast-messages?p=1">All Chats</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>
                      Send New Message
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        Send New Message
                        <span className="float-right">
                          <button
                            onClick={handleDisconnect}
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </button>
                        </span>
                      </h4>
                      {messageVisible && (
                        <p style={{ color: "green" }}>{message}</p>
                      )}
                      {loading && (
                        <div>
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                          <p>Sending...</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                rows="4"
                                cols="50"
                                name="chatMessage"
                                value={chatMessage}
                                type="text"
                                className="form-control"
                                id="exampleInputName1"
                                placeholder="Type your message here..."
                                data-dashlane-rid="1e0992f288aade7f"
                                data-kwimpalastatus="alive"
                                data-kwimpalaid="1685956648162-9"
                                data-form-type="name"
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                              />
                              {errors.chatMessage && (
                                <p style={{ color: "red" }}>
                                  {errors.chatMessage}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                      <button
                        type="button"
                        className={buttonClasses}
                        disabled={!isFormValid || loading}
                        onClick={() => {
                          setLoading(true);
                          sendMessage();
                        }}
                      >
                        Send
                      </button>
                      <button
                        onClick={handleDisconnect}
                        className="btn btn-light"
                        data-dashlane-rid="b3088ec9f9c2421f"
                        data-dashlane-label="true"
                        data-form-type="other"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}

          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    </div>
  );
}
export default SendNewMessage;

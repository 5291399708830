import { ActionTypes } from "../constants/action-types";
const initialState = {
  message: "",
  status: "",
  data: {},
  chatDetails: [],
  loading: false,
};
export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CHAT:
      const { data, loading } = action.payload;
      return {
        ...state,
        loading,
        data,
      };
    case ActionTypes.GET_CHAT_DETAILS:
      return {
        ...state,
        chatDetails: action.payload,
      };

    case ActionTypes.LOADING_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

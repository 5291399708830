import React from 'react';
import { Link } from "react-router-dom";
import TopNavbar from "./component/navbar/TopNavbar";
import Sidebar from "./component/navbar/SideBar";
import Footer from './component/navbar/Footer';

function Notification() {
  return (
    <div>
      <div className="container-scroller">
        {/* Topbar */}
        <TopNavbar />

        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="page-header">
                <h3 class="page-title">
                  <span class="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i class="mdi mdi-home"></i>
                  </span>
                  All Notifications
                </h3>
                <nav aria-label="breadcrumb">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="">All Notifications</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <span></span>Notifications
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="row">
                <div class="col-12 grid-margin">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">
                        All Notifications
                        <span class="float-right">
                          {/* <Link to="/all-users" class="btn btn-sm btn-primary">
                            <i class="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link> */}
                        </span>
                      </h4>

                      <hr class="mt-4" />

                      {/* <div class="row">
                        <div class="col-md-4">
                          <img
                            src="assets/vendors/images/faces/face1.jpg"
                            alt="profile"
                          />
                        </div>

                        <div class="col-md-4">
                          <address class="text-primary">
                            <p class="font-weight-bold">Name</p>
                            <p class="mb-2">Chandra Sharma</p>
                            <p class="font-weight-bold">E-mail</p>
                            <p class="mb-2">johndoe@examplemeail.com</p>
                            <p class="font-weight-bold">Web Address</p>
                            <p>www.Purple.com</p>
                          </address>
                        </div>

                        <div class="col-md-4">
                          <p class="text-primary font-weight-bold">Address</p>
                          <address class="">
                            <p class="font-weight-bold">Purple imc</p>
                            <p>695 lsom Ave,</p>
                            <p>Suite 00</p>
                            <p>San Francisco, CA 94107</p>
                          </address>
                        </div>
                      </div> */}

                      {/* <h4 class="card-title font-weight-bold mt-5">
                        Notifications
                        <span class="float-right"></span>
                      </h4> */}
                      {/* <hr class="mt-4" /> */}

                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="font-weight-bold">ID</th>
                              <th class="font-weight-bold">
                                Last Conversation
                              </th>
                              <th class="font-weight-bold">Last Login</th>
                              <th class="font-weight-bold">Status</th>
                              <th class="font-weight-bold">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>WD-12345</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face1.jpg"
                                  class="mr-2"
                                  alt="image"
                                />
                                David Grey
                              </td>

                              <td>Dec 5, 2017</td>
                              <td>
                                <label class="badge badge-gradient-success">
                                  ACTIVE
                                </label>
                              </td>
                              <td>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i class="mdi mdi-delete"></i>
                                </Link>
                                <a
                                  href="#"
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i class="mdi mdi-block-helper"></i>
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12346</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face2.jpg"
                                  class="mr-2"
                                  alt="image"
                                />
                                Stella Johnson
                              </td>

                              <td>Dec 12, 2017</td>
                              <td>
                                <label class="badge badge-gradient-warning">
                                  UN-APPROVED
                                </label>
                              </td>
                              <td>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i class="mdi mdi-delete"></i>
                                </Link>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i class="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12347</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face3.jpg"
                                  class="mr-2"
                                  alt="image"
                                />
                                Marina Michel
                              </td>

                              <td>Dec 16, 2017</td>
                              <td>
                                <label class="badge badge-gradient-info">
                                  IN-ACTIVE
                                </label>
                              </td>
                              <td>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i class="mdi mdi-delete"></i>
                                </Link>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i class="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12348</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face4.jpg"
                                  class="mr-2"
                                  alt="image"
                                />
                                John Doe
                              </td>

                              <td>Dec 3, 2017</td>
                              <td>
                                <label class="badge badge-gradient-danger">
                                  REJECTED
                                </label>
                              </td>

                              <td>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i class="mdi mdi-delete"></i>
                                </Link>
                                <Link
                                  class="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i class="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
import React, { useEffect } from "react";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardData } from "./redux/actions/dashboardActions";
import { Loader } from "../src/component/loader/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(dashboardData());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  Dashboard
                </h3>
              </div>

              <div className="row">
                <Link
                  to="/all-users?p=1"
                  className="col-md-6 stretch-card grid-margin"
                >
                  <div className="card bg-gradient-danger card-img-holder text-white">
                    <div className="card-body">
                      <img
                        src="../assets/vendors/images/dashboard/circle.svg"
                        className="card-img-absolute"
                        alt="circle-image"
                      />
                      <h4 className="font-weight-normal mb-3">
                        No. of Users
                        <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                      </h4>
                      <h2 className="mb-5">{data?.userCount || 0}</h2>
                    </div>
                  </div>
                </Link>

                <Link
                  to="/reported-users"
                  className="col-md-6 stretch-card grid-margin"
                >
                  <div className="card bg-gradient-info card-img-holder text-white">
                    <div className="card-body">
                      <img
                        src="assets/vendors/images/dashboard/circle.svg"
                        className="card-img-absolute"
                        alt="circle-image"
                      />
                      <h4 className="font-weight-normal mb-3">
                        No. of Reported Users
                        <i className="mdi mdi-alert-outline mdi-24px float-right"></i>
                      </h4>
                      <h2 className="mb-5">{data?.reportedUserCount || 0}</h2>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/all-users?p=1"
                  className="col-md-6 stretch-card grid-margin"
                >
                  <div className="card bg-gradient-success card-img-holder text-white">
                    <div className="card-body">
                      <img
                        src="assets/vendors/images/dashboard/circle.svg"
                        className="card-img-absolute"
                        alt="circle-image"
                      />
                      <h4 className="font-weight-normal mb-3">
                        No. of Blocked Users
                        <i className="mdi mdi-block-helper mdi-24px float-right"></i>
                      </h4>
                      <h2 className="mb-5">{data?.blockedUserCount || 0}</h2>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/broadcast-messages?p=1"
                  className="col-md-6 stretch-card grid-margin"
                >
                  <div className=" card bg-gradient-warning card-img-holder text-white">
                    <div className="card-body ">
                      <img
                        src="../assets/vendors/images/dashboard/circle.svg"
                        className="card-img-absolute"
                        alt="circle-image"
                      />
                      <h4 className="font-weight-normal mb-3">
                        No. of Messages Sent
                        <i className="mdi mdi-message-outline mdi-24px float-right"></i>
                      </h4>
                      <h2 className="mb-5">{data?.messsageCount || 0}</h2>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div className="row">
                <div className="col-md-7 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="clearfix">
                        <h4 className="card-title float-left">
                          Visit And Sales Statistics
                        </h4>
                        <div
                          id="visit-sale-chart-legend"
                          className="rounded-legend legend-horizontal legend-top-right float-right"
                        ></div>
                      </div>
                      <canvas id="visit-sale-chart" className="mt-4"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Traffic Sources</h4>
                      <canvas id="traffic-chart"></canvas>
                      <div
                        id="traffic-chart-legend"
                        className="rounded-legend legend-vertical legend-bottom-left pt-4"
                      ></div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Recent Tickets</h4>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Assignee</th>
                              <th>Subject</th>
                              <th>Status</th>
                              <th>Last Update</th>
                              <th>Tracking ID</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face1.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                David Grey
                              </td>
                              <td>Fund is not recieved</td>
                              <td>
                                <label className="badge badge-gradient-success">
                                  DONE
                                </label>
                              </td>
                              <td>Dec 5, 2017</td>
                              <td>WD-12345</td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face2.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                Stella Johnson
                              </td>
                              <td>High loading time</td>
                              <td>
                                <label className="badge badge-gradient-warning">
                                  PROGRESS
                                </label>
                              </td>
                              <td>Dec 12, 2017</td>
                              <td>WD-12346</td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face3.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                Marina Michel
                              </td>
                              <td>Website down for one week</td>
                              <td>
                                <label className="badge badge-gradient-info">
                                  ON HOLD
                                </label>
                              </td>
                              <td>Dec 16, 2017</td>
                              <td>WD-12347</td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face4.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                John Doe
                              </td>
                              <td>Loosing control on server</td>
                              <td>
                                <label className="badge badge-gradient-danger">
                                  REJECTED
                                </label>
                              </td>
                              <td>Dec 3, 2017</td>
                              <td>WD-12348</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/*  <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Recent Updates</h4>
                      <div className="d-flex">
                        <div className="d-flex align-items-center mr-4 text-muted font-weight-light">
                          <i className="mdi mdi-account-outline icon-sm mr-2"></i>
                          <span>jack Menqu</span>
                        </div>
                        <div className="d-flex align-items-center text-muted font-weight-light">
                          <i className="mdi mdi-clock icon-sm mr-2"></i>
                          <span>October 3rd, 2018</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6 pr-1">
                          <img
                            src="assets/vendors/images/dashboard/img_1.jpg"
                            className="mb-2 mw-100 w-100 rounded"
                            alt="image"
                          />
                          <img
                            src="assets/vendors/images/dashboard/img_4.jpg"
                            className="mw-100 w-100 rounded"
                            alt="image"
                          />
                        </div>
                        <div className="col-6 pl-1">
                          <img
                            src="assets/vendors/images/dashboard/img_2.jpg"
                            className="mb-2 mw-100 w-100 rounded"
                            alt="image"
                          />
                          <img
                            src="assets/vendors/images/dashboard/img_3.jpg"
                            className="mw-100 w-100 rounded"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-5 align-items-top">
                        <img
                          src="assets/vendors/images/faces/face3.jpg"
                          className="img-sm rounded-circle mr-3"
                          alt="image"
                        />
                        <div className="mb-0 flex-grow">
                          <h5 className="mr-2 mb-2">
                            School Website - Authentication Module.
                          </h5>
                          <p className="mb-0 font-weight-light">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page.
                          </p>
                        </div>
                        <div className="ml-auto">
                          <i className="mdi mdi-heart-outline text-muted"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-md-7 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Project Status</h4>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Due Date</th>
                              <th>Progress</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Herman Beck</td>
                              <td>May 15, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-success"
                                    role="progressbar"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Messsy Adam</td>
                              <td>Jul 01, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-danger"
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>John Richards</td>
                              <td>Apr 12, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-warning"
                                    role="progressbar"
                                    aria-valuenow="90"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Peter Meggik</td>
                              <td>May 15, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-primary"
                                    role="progressbar"
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Edward</td>
                              <td>May 03, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-danger"
                                    role="progressbar"
                                    aria-valuenow="35"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Ronald</td>
                              <td>Jun 05, 2015</td>
                              <td>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-gradient-info"
                                    role="progressbar"
                                    aria-valuenow="65"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-white">Todo</h4>
                      <div className="add-items d-flex">
                        <input
                          type="text"
                          className="form-control todo-list-input"
                          placeholder="What do you need to do today?"
                        />
                        <button
                          className="add btn btn-gradient-primary font-weight-bold todo-list-add-btn"
                          id="add-task"
                        >
                          Add
                        </button>
                      </div>
                      <div className="list-wrapper">
                        <ul className="d-flex flex-column-reverse todo-list todo-list-custom">
                          <li>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="checkbox" type="checkbox" />{" "}
                                Meeting with Alisa
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                          <li className="completed">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  checked
                                />
                                Call John
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                          <li>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="checkbox" type="checkbox" />{" "}
                                Create invoice
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                          <li>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="checkbox" type="checkbox" /> Print
                                Statements
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                          <li className="completed">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  checked
                                />
                                Prepare for presentation
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                          <li>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="checkbox" type="checkbox" /> Pick
                                up kids from school
                              </label>
                            </div>
                            <i className="remove mdi mdi-close-circle-outline"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <Footer />
          </div>
          {/* <!-- Ending Of Main Content--> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </div>
  );
};

export default Dashboard;

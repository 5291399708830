import { ActionTypes } from "../constants/action-types";

const initialState = {
  code: "",
  message: "",
  users: [],
  success: false,
  meta: {},
  userCount: "",
  currentPage: 1,
  totalPages: "",
  loading: false,
  deleteUserData: {},
  reasonList: [],
  reportedUserData: {},
  reportStatusData: {},
  userStatusData: {},
  changePasswordData: {},
  reportReasonData: {},
  updatedReportedReasonData: {},
  deleteReportData: {},
};

export const userReducer = (state = initialState, action) => {
  let code;
  let message;
  let success;
  let data;
  let loading;

  switch (action.type) {
    case ActionTypes.FETCH_USER:
      ({ data, code, message, success, loading } = action.payload);
      return {
        ...state,
        code,
        message,
        success,
        users: data?.userList,
        userCount: data?.userCount,
        currentPage: data?.currentPage,
        totalPages: data?.totalPages,
        loading,
      };
    case ActionTypes.REPORTED_USERS:
      ({ loading } = action.payload);
      return {
        ...state,
        reportedUserData: action.payload,
        loading,
      };

    case ActionTypes.REPORT_REASON_LIST:
      ({ data, code, message, success, loading } = action.payload);
      return {
        ...state,
        code,
        message,
        success,
        reasonList: data?.reasonsList,
        currentPage: data?.currentPage,
        totalPages: data?.totalPages,
        loading,
      };

    case ActionTypes.ADD_NEW_REASON:
      ({ data, code, message, success } = action.payload);
      return {
        ...state,
        reportReasonData: action.payload,
        loading,
      };

    case ActionTypes.DELETE_REPORT_REASON:
      ({ code, message, success, data } = action.payload);
      return {
        ...state,
        deleteReportData: action.payload,
      };

    case ActionTypes.UPDATE_REPORT_REASON:
      ({ code, message, success, data } = action.payload);
      return {
        ...state,
        updatedReportedReasonData: action.payload,
        loading,
      }   

    case ActionTypes.CHANGE_REPORT_STATUS:
      ({ data, code, message, success } = action.payload);
      return {
        ...state,
        reportStatusData: action.payload,
      };

    case ActionTypes.CHANGE_USER_STATUS:
      ({ data, code, message, success } = action.payload);
      return {
        ...state,
        userStatusData: action.payload,
      };
    case ActionTypes.CHANGE_PASSWORD:
      ({ loading } = action.payload);
      return {
        ...state,
        changePasswordData: action.payload,
        loading,
      };
    case ActionTypes.ADD_NEW_USER:
      ({ code, message, success, data } = action.payload);
      return {
        ...state,
        code,
        message,
        success,
        loading,
      };
    case ActionTypes.EDIT_USER:
      ({ code, message, success, data } = action.payload);
      return {
        ...state,
        code,
        message,
        success,
        loading,
      };
    case ActionTypes.DELETE_USER:
      ({ code, message, success, data } = action.payload);
      return {
        ...state,
        deleteUserData: action.payload,
      };

    case ActionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        reportReasonData: {
          ...state.reportReasonData,
          message: "",
        },
        updatedReportedReasonData: {
          ...state.updatedReportedReasonData,
          message: "",
        },
        message: "",
        currentPage: 1,
      };
    case ActionTypes.LOADING_ACTION:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import Sidebar from "./component/navbar/SideBar";
import { validateInput } from "./helpers/util";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./component/navbar/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../src/component/loader/Loader";
import { clearMessage, changePassword } from "./redux/actions/userActions";
import { ActionTypes } from "./redux/constants/action-types";

function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);

  const { changePasswordData, loading } = useSelector((state) => state.users);

  const message = changePasswordData?.message || "";
  const success = changePasswordData?.success || false;

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  useEffect(() => {
    dispatch({ type: ActionTypes.LOADING_ACTION, payload: { loading: false } });
  }, []);

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  useEffect(() => {
    if (
      errors.oldPassword === "" &&
      errors.password === "" &&
      errors.confirmPassword === ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [errors]);
  const submitHandler = () => {
    if (password !== confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Password did not match",
      });
      setIsFormValid(false);
    } else if (isFormValid) {
      dispatch(changePassword(oldPassword, password));
      setMessageVisible(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageVisible(false);
    if (name === "oldPassword") {
      setErrors({
        ...errors,
        oldPassword: "",
      });
      setOldPassword(value);
    } else if (name === "password") {
      setErrors({
        ...errors,
        password: "",
      });
      setPassword(value);
    } else {
      setErrors({
        ...errors,
        confirmPassword: "",
      });
      setConfirmPassword(value);
    }
  };

  useEffect(() => {
    if (messageVisible && success) {
      const delayNavigation = setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
      return () => clearTimeout(delayNavigation);
    } else {
      return;
    }
  }, [messageVisible, success]);

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateInput(name, value, password);
    if (validationError) {
      setErrors({
        ...errors,
        [name]: validationError,
      });
    }
  };
  const buttonClasses = `btn btn-gradient-primary mr-2 ${
    !isFormValid ? "disabled" : ""
  }`;

  const getMessageStyle = () => {
    if (success) {
      return "success-message";
    } else {
      return "error-message";
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2 mt-5">
                    <i className="mdi mdi-home"></i>
                  </span>
                  Dashboard
                </h3>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        Change Password
                        <span className="float-right">
                          <Link
                            to="/dashboard"
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link>
                        </span>
                      </h4>
                      {loading && <Loader />}
                      {messageVisible && !loading && (
                        <p className={getMessageStyle()}>{message}</p>
                      )}
                      {/* <p className={getMessageStyle()}>
                        {messageVisible && message}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title font-weight-bold">
                        {isEditMode ? "Edit User" : "Add New User"}
                      </h4> */}

                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword4">
                                Old Password
                              </label>
                              <div className="showpwrd">
                                <input
                                  name="oldPassword"
                                  value={oldPassword}
                                  type={showOldPassword ? "text" : "password"}
                                  className="form-control"
                                  id="exampleInputPassword4"
                                  placeholder="Password"
                                  data-dashlane-rid="89b55a5026fdd08d"
                                  data-kwimpalastatus="alive"
                                  data-kwimpalaid="1685956648162-11"
                                  data-form-type="password"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />

                                <i
                                  onClick={handleShowOldPassword}
                                  className={`mdi ${
                                    showOldPassword
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  } menu-icon`}
                                ></i>
                              </div>
                              {errors.oldPassword && (
                                <p style={{ color: "red" }}>
                                  {errors.oldPassword}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputConfirmPassword1">
                                New Password
                              </label>
                              <div className="showpwrd">
                                <input
                                  name="password"
                                  value={password}
                                  type={showNewPassword ? "text" : "password"}
                                  className="form-control"
                                  id="exampleInputConfirmPassword1"
                                  placeholder="Confirm Password"
                                  data-dashlane-rid="31050e9eefee0b5a"
                                  data-kwimpalastatus="alive"
                                  data-kwimpalaid="1685956648162-3"
                                  data-form-type="password,confirmation"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />
                                <i
                                  onClick={handleShowNewPassword}
                                  className={`mdi ${
                                    showNewPassword
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  } menu-icon`}
                                ></i>
                              </div>
                              {errors.password && (
                                <p style={{ color: "red" }}>
                                  {errors.password}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputConfirmPassword2">
                                Confirm New Password
                              </label>
                              <div className="showpwrd">
                                <input
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  type={
                                    showConfirmNewPassword ? "text" : "password"
                                  }
                                  className="form-control"
                                  id="exampleInputConfirmPassword2"
                                  placeholder="Confirm Password"
                                  data-dashlane-rid="31050e9eefee0b5a"
                                  data-kwimpalastatus="alive"
                                  data-kwimpalaid="1685956648162-3"
                                  data-form-type="password,confirmation"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />
                                <i
                                  onClick={handleShowConfirmNewPassword}
                                  className={`mdi ${
                                    showConfirmNewPassword
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  } menu-icon`}
                                ></i>
                              </div>
                              {errors.confirmPassword && (
                                <p style={{ color: "red" }}>
                                  {errors.confirmPassword}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className={buttonClasses}
                          disabled={!isFormValid || loading}
                          onClick={submitHandler}
                        >
                          Submit
                        </button>
                        <Link
                          to="/dashboard"
                          className="btn btn-light"
                          data-dashlane-rid="b3088ec9f9c2421f"
                          data-dashlane-label="true"
                          data-form-type="other"
                        >
                          Cancel
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {/* Main Content */}

          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    </div>
  );
}
export default ChangePassword;

import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";
import { useSelector, useDispatch } from "react-redux";
import { getChatDetails } from "./redux/actions/chatActions";
import { clearMessage } from "./redux/actions/userActions";
import { NoData } from "../src/component/noDataText/NoData";

function ViewAllMessages() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { chatDetails } = useSelector((state) => state.chat);
  const user = chatDetails?.data?.chatList?.groupMember.filter(
    (grp) => grp.isAdmin !== 1
  );

  const firstName = user?.map((user) => user?.user?.firstName);
  const lastName = user?.map((user) => user?.user?.lastName);
  const messages = chatDetails?.data?.chatList?.messages;
  const currentPage = new URLSearchParams(location.search).get("p") || 1;
  const page = new URLSearchParams(location.search).get("chatPage") || 1;
  const totalPages = chatDetails?.data?.totalPages || 1;

  const [value, setValue] = useState(20);
  const [chatPage, setChatPage] = useState(Number(page));
  const options = [
    { id: "1", label: "50", value: "50" },
    { id: "2", label: "20", value: "20" },
    { id: "3", label: "10", value: "10" },
  ];
  useEffect(() => {
    const newPage = Number(page);
    if (newPage !== chatPage) {
      setChatPage(newPage);
    }
  }, [location.search]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const previousHandler = () => {
    setChatPage(chatPage - 1);
  };

  const nextHandler = () => {
    setChatPage(chatPage + 1);
  };
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getChatDetails(id, chatPage, value));

    return () => {
      dispatch(clearMessage());
    };
  }, []);
  useEffect(() => {
    dispatch(getChatDetails(id, chatPage, value));
    navigate(
      `${window.location.pathname}?p=${currentPage}&chatPage=${chatPage}`
    );
  }, [id, value, chatPage]);
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <div className="main-panel">
            <div className="content-wrapper" style={{ marginTop: 44 }}>
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Chats
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/broadcast-messages?p=1">All Chats</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>
                      View All Messages
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        {firstName} {lastName}
                        <span className="float-right">
                          <Link
                            to={`/broadcast-messages?p=${currentPage}`}
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        All Messages
                      </h4>

                      <hr className="mt-4" />

                      <div className="row">
                        <div className="col-12">
                          {messages?.length === 0 ? (
                            <NoData />
                          ) : (
                            messages?.map((msg) => (
                              <div key={msg.id} className="messages">
                                <p>{msg.message}</p>
                                <div className="time">
                                  <small>
                                    {new Date(msg.createdAt).toLocaleDateString(
                                      "en-US"
                                    )}{" "}
                                    {new Date(msg.createdAt).toLocaleTimeString(
                                      "en-US"
                                    )}
                                  </small>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="mt-3 d-flex align-items-center justify-content-center">
                          <button
                            className={`btn btn-sm btn-primary m-3 ${
                              chatPage === 1 && "disabled"
                            }`}
                            style={{ width: 100 }}
                            onClick={previousHandler}
                            disabled={chatPage === 1}
                          >
                            &lt; Previous
                          </button>
                          <button
                            className={`btn btn-sm btn-primary m-3 ${
                              chatPage === totalPages && "disabled"
                            }`}
                            style={{ width: 100 }}
                            onClick={nextHandler}
                            disabled={chatPage === totalPages}
                          >
                            Next &gt;
                          </button>

                          <div className="mt-1 ml-5 d-flex align-items-center justify-content-center">
                            <label>
                              Page Size
                              <select
                                value={value}
                                onChange={handleChange}
                                className={`btn dropdown btn-sm ml-2 ${
                                  chatPage !== 1 && "disabled"
                                }`}
                                disabled={chatPage === 1 ? false : true}
                              >
                                {options.map((option) => (
                                  <option
                                    key={option.id}
                                    value={option.value}
                                    style={{ fontSize: "15px" }}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAllMessages;

import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Sidebar from "./component/navbar/SideBar";
import Footer from "./component/navbar/Footer";
import { useSelector } from "react-redux";

function ViewUser() {
  const { id } = useParams();
  const { users, reportedUserData } = useSelector((state) => state.users);

  const userList = reportedUserData?.data?.userList || [];
  const user = users.find((el) => el.id === parseInt(id));
  const reportedUser = userList.find((el) => el.id === parseInt(id));
  const location = useLocation();
  const isReportedUser = location.pathname?.includes("/view-reported-user");
  const currentPage = new URLSearchParams(location.search).get("p") || 1;

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <div className="main-panel">
            <div className="content-wrapper" style={{ marginTop: 44 }}>
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2">
                    <i className="mdi mdi-home"></i>
                  </span>
                  All Users
                </h3>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      {isReportedUser ? (
                        <Link to="/reported-users">Reported Users</Link>
                      ) : (
                        <Link to="/all-users?p=1">All Users</Link>
                      )}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span></span>
                      {isReportedUser ? "View Reported User" : "View User"}
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title font-weight-bold">
                        User Personal Details
                        <span className="float-right">
                          <Link
                            to={
                              isReportedUser
                                ? `${"/reported-users"}`
                                : `/all-users?p=${currentPage}`
                            }
                            className="btn btn-sm btn-primary"
                          >
                            <i className="mdi mdi-keyboard-backspace"></i>
                            Back
                          </Link>
                        </span>
                      </h4>

                      <hr className="mt-4" />

                      <div className="row">
                        <div className="col-md-4">
                          <img
                            src="./../assets/vendors/images/faces/profile.png"
                            alt="profile"
                          />
                        </div>

                        <div className="col-md-4 mt-5">
                          <address className="">
                            <p className="text-primary font-weight-bold">
                              Name
                            </p>
                            <p className="mb-2">
                              {isReportedUser
                                ? `${reportedUser?.blockedTo.firstName} ${reportedUser?.blockedTo.lastName}`
                                : `${user?.firstName} ${user?.lastName}`}
                            </p>
                            <p className="text-primary font-weight-bold">
                              E-mail
                            </p>
                            <p className="mb-2">
                              {isReportedUser
                                ? `${reportedUser?.blockedTo.email}`
                                : `${user?.email}`}
                            </p>
                            {/* <p className="text-primary font-weight-bold">
                              Web Address
                            </p>
                            <p>www.Purple.com</p> */}
                          </address>
                        </div>

                        {/* <div className="col-md-4">
                          <p className="text-primary font-weight-bold">
                            Address
                          </p>
                          <address className="">
                            <p className="font-weight-bold">Purple imc</p>
                            <p>695 lsom Ave,</p>
                            <p>Suite 00</p>
                            <p>San Francisco, CA 94107</p>
                          </address>
                        </div> */}
                      </div>

                      {/* <h4 className="card-title font-weight-bold mt-5">
                        User Activity Log
                        <span className="float-right"></span>
                      </h4>
                      <hr className="mt-4" />

                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">ID</th>
                              <th className="font-weight-bold">
                                Last Conversation
                              </th>
                              <th className="font-weight-bold">Last Login</th>
                              <th className="font-weight-bold">Status</th>
                              <th className="font-weight-bold">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>WD-12345</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face1.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                David Grey
                              </td>

                              <td>Dec 5, 2017</td>
                              <td>
                                <label className="badge badge-gradient-success">
                                  ACTIVE
                                </label>
                              </td>
                              <td>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i className="mdi mdi-delete"></i>
                                </Link>
                                <a
                                  href="#"
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i className="mdi mdi-block-helper"></i>
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12346</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face2.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                Stella Johnson
                              </td>

                              <td>Dec 12, 2017</td>
                              <td>
                                <label className="badge badge-gradient-warning">
                                  UN-APPROVED
                                </label>
                              </td>
                              <td>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i className="mdi mdi-delete"></i>
                                </Link>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i className="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12347</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face3.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                Marina Michel
                              </td>

                              <td>Dec 16, 2017</td>
                              <td>
                                <label className="badge badge-gradient-info">
                                  IN-ACTIVE
                                </label>
                              </td>
                              <td>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i className="mdi mdi-delete"></i>
                                </Link>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i className="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td>WD-12348</td>
                              <td>
                                <img
                                  src="assets/vendors/images/faces/face4.jpg"
                                  className="mr-2"
                                  alt="image"
                                />
                                John Doe
                              </td>

                              <td>Dec 3, 2017</td>
                              <td>
                                <label className="badge badge-gradient-danger">
                                  REJECTED
                                </label>
                              </td>

                              <td>
                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Remove"
                                >
                                  <i className="mdi mdi-delete"></i>
                                </Link>

                                <Link
                                  className="btn btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Block"
                                >
                                  <i className="mdi mdi-block-helper"></i>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;

export const ActionTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  GET_PROFILE: "GET_PROFILE",
  FETCH_USER: "FETCH_USER",
  REPORTED_USERS: "REPORTED_USERS",
  REPORT_REASON_LIST: "REPORT_REASON_LIST",
  ADD_NEW_REASON: "ADD_NEW_REASON",
  DELETE_REPORT_REASON: "DELETE_REPORT_REASON",
  UPDATE_REPORT_REASON: "UPDATE_REPORT_REASON",
  CHANGE_REPORT_STATUS: "CHANGE_REPORT_STATUS",
  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  ADD_NEW_USER: "ADD_NEW_USER",
  EDIT_USER: "EDIT_USER",
  GET_CHAT: " GET_CHAT",
  GET_CHAT_DETAILS: "GET_CHAT_DETAILS",
  DELETE_USER: "DELETE_USER",
  LOADING_ACTION: "LOADING_ACTION",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  DASHBOARD_DATA: "DASHBOARD_DATA",
  CLEAR_ERROR: "CLEAR_ERROR",
};

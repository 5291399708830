import { ActionTypes } from "../constants/action-types";
const initialState = {
  message: "",
  success: false,
  code: "",
  data: {},
  loading: false,
  profileData: {},
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.DASHBOARD_DATA:
      const { data, loading } = action.payload;
      return {
        ...state,
        loading,
        data,
      };
    case ActionTypes.GET_PROFILE:
      const { data: profileData } = action.payload;
      return {
        ...state,
        profileData: profileData,
      };
    case ActionTypes.LOADING_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { userReducer } from "./userReducer";
import { dashboardReducer } from "./dashboardReducer";
import { chatReducer } from "./chatReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  users: userReducer,
  dashboard: dashboardReducer,
  chat: chatReducer,
});

export default rootReducer;

import React from "react";

export const NoData = () => {
    return (
        <div className=" mt-4 ">
            <h5>No Data Available</h5>
        </div>
    );
};

export default NoData;